import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class ManagePurchaseOrderService{
  
    getFilteredPurchaseOrders(queryParams)
    {
        const url = `api/get-purchase-orders?page-index=${queryParams.pageIndex}`
        const params ={
            'branch':queryParams.branch,
            'today': queryParams.today,
            'month':queryParams.month,
            'year':queryParams.year,
            'from':queryParams.from_date,
            'to':queryParams.to_date,
            'searched':queryParams.searched,
            'page-reload':queryParams.page_reload,
            'offset':queryParams.offset
        }
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
        
    }

    getSlipPredefinedData(){
        const url = `api/purchase/slip/create`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);

    }

    cancelPurchaseOrder(id,remarks){
        const url = `api/purchase/order/cancel/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,remarks,headers);
    }
}

export default new ManagePurchaseOrderService();