import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class PurchaseOrder{
    getDataForPurchaseCreation(){
        const url = 'api/purchase/order/create'
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    getPurchaseItemsByBranch(id){
        const url = `api/purchase/order/branch/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);  
    }

    getItemsBySearchKeyword(params){
        const url = `api/purchase/items/search`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }

    getSuppliersBySearchKeyword(keyword){
        let params={
            'page-index':15,
            'searched':keyword,
            'offset':0
        }
        const url = `api/supplier/filter`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }

    storePurchaseOrderData(purchaseData)
    {
        const url = `api/purchase/order/store`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,purchaseData,headers);
    }

    getCheckoutData(id)
    {
        const url = `api/purchase/order/edit/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    updatePurchaseOrderData(purchaseData,id)
    {
        const url = `api/purchase/order/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,purchaseData,headers);
    }

}

export default new PurchaseOrder();