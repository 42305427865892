import Purchase from './PurchaseService';
import ManagePurchase from "./ManagePurchaseService";
import PurchaseOrder from "./PurchaseOrderService";
import ManagePurchaseOrder from "./ManagePurchaseOrderService";
import PurchaseEdit from "./PurchaseEditService";
import PurchaseOrderCheckout from "./PurchaseOrderCheckoutService";
import PurchaseReturn from "./PurchaseReturnService";
import ManagePurchaseReturn from "./ManagePurchaseReturnService";
import PurchaseSetting from "./PurchaseSetting";
export default{
    Purchase,
    ManagePurchase,
    PurchaseOrder,
    ManagePurchaseOrder,
    PurchaseEdit,
    PurchaseOrderCheckout,
    PurchaseReturn,
    ManagePurchaseReturn,
    PurchaseSetting,
}