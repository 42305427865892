<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-8">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Purchase</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Purchase Return
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">
              Manage Purchase Return
            </h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select
            v-model="filterParams.pageIndex"
            @change="selectTotalData"
            class="mr-2 mg-t-5"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value>All</option>
          </select>
          <select
            v-model="filterParams.branch"
            class="filter-input mr-2 mg-t-5"
            @change="selectBranch"
          >
            <option value>All Branch</option>
            <option
              v-for="(branch, index) in slipSettings.branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>

          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="filterParams.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @input="selectFromDate"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="filterParams.to_date"
              placeholder="To"
              class="ml-1 filter-input mr-1 mg-t-5"
              @input="selectEndDate"
            />
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="filterParams.searched"
            class="float-right"
            placeholder="Search..."
            @keyup="searchedKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Return ID</th>
              <th class="wd-10p">Purchase ID</th>
              <th class="wd-15p">Supplier Name</th>
              <th class="wd-10p">Return Amt.</th>
              <th class="wd-5p" v-if="authData.general_setting.vatable == 1">
                VAT Amt.
              </th>
              <th class="wd-10p">Paid Amt.</th>
              <th class="wd-10p">Paytment Mode</th>
              <th
                :class="
                  authData.general_setting.vatable == 1 ? 'wd-10' : 'wd-5'
                "
              >
                Due
              </th>
              <th
                class="wd-10p text-center no-print"
                v-if="
                  checkIfAnyPermission([
                    'view_purchase_return',
                    'cancel_purchase_return',
                  ])
                "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="!loading && dataSets.length > 0">
            <tr v-for="(purchase, index) in dataSets" :key="index">
              <th scope="row" class="table-start-item">{{ ++index }}</th>
              <td>{{ purchase.date }}</td>
              <td>{{ parseDigitForSlip(purchase.purchase_return_no) }}</td>
              <td>
                {{ purchase.purchase_detail.prefix
                }}{{ purchase.purchase_detail.purchase_no }}
              </td>
              <td>{{ purchase.supplier.name }}</td>
              <td>{{ parseDigitForSlip(purchase.total) }}</td>
              <td v-if="authData.general_setting.vatable == 1">
                {{ parseDigitForSlip(purchase.vat_amount) }}
              </td>
              <td>{{ parseDigitForSlip(purchase.paid_amount) }}</td>
              <td>
                <span v-if="purchase.payment_methods">{{
                  purchase.payment_methods.title
                }}</span>
              </td>
              <td>{{ parseDigitForSlip(purchase.due_amount) }}</td>
              <td
                class="text-center no-print"
                v-if="
                  checkIfAnyPermission([
                    'view_purchase_return',
                    'cancel_purchase_return',
                  ])
                "
              >
                <a href="javascript:;" class="mr-3" title="View Slip">
                  <i
                    class="fas fa-eye tx-success"
                    @click="view('returnSlip', purchase.id)"
                  ></i>
                </a>
                <a
                  title="Cancel Purchase Return"
                  href="javascript:;"
                  class="mr-3"
                  @click="cancelPurchase(purchase.id)"
                  v-if="
                    purchase.status == 1 &&
                    checkSinglePermission('cancel_purchase_return')
                  "
                >
                  <i class="fa fa-times-circle tx-danger"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ errorMessage }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && dataSets.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
        <ReturnSlip @printSlip="printSlip" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ReturnSlip from "./ReturnSlip";
import Paginate from "vuejs-paginate";
import Services from "./Services/Service";
import SearchDayMonthComponent from "../../../shared/SearchDayMonthComponent";
import _ from "lodash";

export default {
  components: {
    Paginate,
    SearchDayMonthComponent,
    ReturnSlip,
  },
  data() {
    return {
      loading: true,
      error: false,
      errorMessage: "",
      totalPageCount: 0,
      pageReload: "",
      page: 1,
      pageSerialNo: 1,
      filterParams: {
        pageIndex: 10,
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
      "authData",
    ]),
    ...mapGetters("purchase", ["dataSets", "slipSettings"]),
  },
  beforeMount() {
    this.getFilteredData();
    this.pageReload = true;
    Services.ManagePurchaseReturn.getSlipPredefinedData()
      .then((response) => {
        this.$store.commit("purchase/setSlipSettings", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.filterParams.from_date = "";
        this.filterParams.to_date = "";
        this.filterParams.day = value.day;
        this.filterParams.month = value.month;
        this.filterParams.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.filterParams.pageIndex + 1;
      }
      this.offset = (page - 1) * this.filterParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    selectTotalData() {
      this.getFilteredDataWithoutDates();
    },
    selectBranch() {
      this.getFilteredDataWithoutDates();
    },
    selectSalesType() {
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      if (this.filterParams.from_date) {
        this.getFilteredDataWithoutDates();
      }
    },
    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.filterParams.offset = 0;
      this.$store.commit("purchase/setItemSearchLoading", true);
      this.getFilteredDataWithoutDates();
    }, 500),

    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },

    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      Services.ManagePurchaseReturn.getFilteredPurchaseReturns(params)
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("purchase/setDataSets", value.data);
          this.totalPageCount = value.total_no_of_pages;
          this.$store.commit("purchase/setItemSearchLoading", false);
        })
        .catch((error) => {
          this.$store.commit("purchase/setItemSearchLoading", true);

          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              // this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.$store.commit("purchase/setItemSearchLoading", false);
          this.loading = false;
        });
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    printSlip(id) {
      console.log(id);
      //once the modal is opened popup print window
      window.print();
    },
    cancelPurchase(id) {
      this.$swal({
        title: "Do you really want to cancel this purchase return?",
        html: `<label>Remarks </label>
        <textarea id="textarea" class="swal2-input"></textarea>
       `,
        showCancelButton: true,
        // confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          let remarks = document.getElementById("textarea").value;
          // cancel the sales with remarks
          return Services.ManagePurchaseReturn.cancelPurchaseReturn(id, {
            remarks: remarks,
          })
            .then((response) => {
              this.dataSets.forEach((item, index) => {
                if (item.id == id) {
                  this.dataSets[index]["status"] = 2;
                  this.dataSets[index]["cancel_note"] = remarks;
                }
              });
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          //poping up the PurchaseSlip modalif
          this.$store.commit("setDataId", result.value.data.id);

          this.$store
            .dispatch("modalWithMode", { id: "returnSlip", mode: "read" })
            .then(() => {
              // setTimeout(()=>{
              //   this.printSlip(result.value.data.id);
              // },1000);
            });
        }
      });
    },
    // Notificaion
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
  },

  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>