import { store } from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class ManagePurchaseService {

    getFilteredPurchases(queryParams) {
        const url = `api/get-purchases?page-index=${queryParams.pageIndex}`
        const params = {
            'branch': queryParams.branch,
            "opening_stock": queryParams.opening_stock,
            'today': queryParams.today,
            'month': queryParams.month,
            'year': queryParams.year,
            'from': queryParams.from_date,
            'to': queryParams.to_date,
            'searched': queryParams.searched,
            'page-reload': queryParams.page_reload,
            'offset': queryParams.offset
        }
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);

    }

    getSlipPredefinedData() {
        const url = `api/purchase/slip/create`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);

    }

    cancelPurchase(id, remarks) {
        const url = `api/purchase/cancel/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url, remarks, headers);
    }

    cancelOpeningStock(id,remarks){
        const url = `api/stock/opening-stock/cancel/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url, remarks, headers);
    }
}

export default new ManagePurchaseService();