import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class PurchaseEditService{
    getPurchaseDataById(id)
    {
        const url = `api/purchase/edit/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    updatePurchase(data,id){
        const url = `api/purchase/update/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,data,headers);
    }

    
}

export default new PurchaseEditService();