import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class PurchaseSettingService{
    createPurchaseSetting(formData)
    {
        const url = `api/settings/purchase`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }

    updatePurchaseSetting(formData,id)
    {
        const url = `api/settings/purchase/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formData,headers);
    }

}

export default new PurchaseSettingService();