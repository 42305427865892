import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class PurchaseService{
    getDataForPurchaseCreation(){
        const url = 'api/purchase/create'
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    getPurchaseItemsByBranch(id){
        const url = `api/purchase/branch/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);  
    }

    getItemsBySearchKeyword(params){
        const url = `api/purchase/items/search`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }

    getSuppliersBySearchKeyword(keyword){
        let params={
            'page-index':15,
            'searched':keyword,
            'offset':0
        }
        const url = `api/supplier/filter`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }

    storePurchaseData(purchaseData)
    {
        const url = `api/purchase/store`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,purchaseData,headers);
    }

}

export default new PurchaseService();