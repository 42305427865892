import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class PurchaseReturnService{
    getPurchaseDataById(id)
    {
        const url = `api/purchase/return-details/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    returnPurchase(data){
        const url = `api/purchase/return/store`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,data,headers);
    }

    cancelPurchase(){

    }

}

export default new PurchaseReturnService();